import React, { useState, useEffect } from "react";
import { Table, Card, Space, Upload } from "lib";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  TablePaginationConfig,
} from "antd";
import { Layout } from "layout";
import { ClientsClient, ClientBriefDto } from "@api";
import { showError, Download } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";
import ClientMenu from "@components/Admin/Clients/ClientMenu";
import { RcFile } from "antd/lib/upload";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import GeneralFormModal from "@components/GeneralFormModal";

const defaultOrderBy = "created";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Page = () => {
  const auth = new SubpageAuth(SECTION.Admin, "Clients", "Clients");
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const clientsClient = new ClientsClient();

  const [state, setState] = useState({
    items: [] as ClientBriefDto[],
    pagination: defaultPagination,
    loading: false,
  });
  
  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "Clients")) return;

    setState({
      ...state,
      loading: true,
    });
    const res = await clientsClient.getClientsWithPagination(
      _keywords ?? keywords,
      params.pagination.current,
      params.pagination.pageSize,
      capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
      (params.sortOrder ?? defaultSortOrder) == "ascend"
    );
    setState({
      ...state,
      items: res.items ?? [],
      pagination: {
        ...params.pagination,
        total: res.totalCount,
      },
      loading: false,
    });
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function handleDownloadClients() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await clientsClient.export();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadClients(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await clientsClient.upload({ data: f, fileName: f.name });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  const handleAddClient = async (data: any) => {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticCreatingMessage" />
    );
    try {
      await clientsClient.create(data);
      messageApi.success(<FormattedMessage id="StaticCreateSuccessMessage" />);
      setCreateModalVisible(false);
      await fetchItems();
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  };

  const handleCancelAddClient = () => {
    setCreateModalVisible(false);
  };

  function OpenModalEditClient(record: ClientBriefDto) {
    editForm.setFieldsValue(record);
    setEditModalVisible(true);
  }

  async function handleEditClient() {
    try {
      const values = await editForm.validateFields();
      if (values) {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await clientsClient.update(values.id, values);
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          setEditModalVisible(false);
          editForm.resetFields();
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      }
    } catch (err) {
      /* form error block */
    }
  }

  function handleCancelEditClient() {
    setEditModalVisible(false);
    editForm.resetFields();
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
    await fetchItems(undefined, values.keywords);
  }

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: true,
    //   defaultSortOrder,
    // },
    {
      // title: "Name",
      title: <FormattedMessage id="ClientTableHeaderName" />,
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      // title: "User Name Prefix",
      title: <FormattedMessage id="ClientTableHeaderUserNamePrefix" />,
      dataIndex: "userNamePrefix",
      key: "userNamePrefix",
      sorter: true,
    },
    {
      // title: "Time added",
      title: <FormattedMessage id="ClientTableHeaderTimeAdded" />,
      dataIndex: "created",
      key: "created",
      sorter: true,
      defaultSortOrder,
      render: (d: Date) => <>{d?.toLocaleString()}</>,
    },
    {
      // title: "Action",
      title: <FormattedMessage id="ClientTableHeaderAction" />,
      render: (text: any, record: ClientBriefDto) => (
        <Space>
          <Button type="primary" onClick={() => OpenModalEditClient(record)}>
            <Space>
              <FormattedMessage id="ClientClientTableEditButton" />
            </Space>
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Layout title="ClientClientNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <ClientMenu />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                onFinish={handleKeywordsSearch}
              >
                <Form.Item name="keywords">
                  <Input placeholder={StaticKeywords} />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      <FormattedMessage id="StaticSearchLabel" />
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Space>
            <Space wrap>
              <Button
                type="primary"
                onClick={() => {
                  setCreateModalVisible(true);
                }}
              >
                <Space>
                  <PlusOutlined />
                  <FormattedMessage id="ClientClientContentAddButton" />
                </Space>
              </Button>
              {auth.can(ACTION.Download, "Clients") ? (
                <Button type="primary" onClick={handleDownloadClients}>
                  <FormattedMessage id="ClientClientContentDownloadButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "Clients") ? (
                <Upload action={handleUploadClients}>
                  <Button type="primary">
                    <FormattedMessage id="ClientClientContentUploadButton" />
                  </Button>
                </Upload>
              ) : null}
            </Space>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.id}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
          />
        </Space>
      </Card>
      <GeneralFormModal
        visible={createModalVisible}
        handleAction={handleAddClient}
        handleCancelAction={handleCancelAddClient}
        titleId={"ClientClientModalAddTitle"}
        editableFields={[
          {
            name: "name",
            labelId: "ClientClientStaticNameLabel",
            type: "string",
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientClientStaticMissingNameRule" />
                ),
              },
            ],
          },
          {
            name: "userNamePrefix",
            labelId: "ClientClientStaticPrefixLabel",
            type: "string",
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientClientStaticMissingPrefixRule" />
                ),
              },
            ],
          },
        ]}
      />
      <Modal
        visible={editModalVisible}
        title={<FormattedMessage id="ClientClientModalEditTitle" />}
        okText={<FormattedMessage id="StaticUpdateButton" />}
        cancelText={<FormattedMessage id="StaticCancelButton" />}
        onOk={handleEditClient}
        onCancel={handleCancelEditClient}
        destroyOnClose={true}
      >
        <Form layout="horizontal" form={editForm} {...formItemLayout}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label={<FormattedMessage id="ClientClientStaticNameLabel" />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientClientStaticMissingNameRule" />
                ),
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name="userNamePrefix"
            label={<FormattedMessage id="ClientClientStaticPrefixLabel" />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="ClientClientStaticMissingPrefixRule" />
                ),
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
        </Form>
      </Modal>
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
